import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ libraryStatus, setLibraryStatus }) => {
  return (
    <div>
      <nav>
        <h2>Music</h2>
        <button
          id="lib"
          onClick={() => {
            setLibraryStatus(!libraryStatus);
            let lib = document.getElementById("lib");
            lib.classList.toggle("active");
          }}
        >
          Library
          <FontAwesomeIcon icon={faMusic} />
        </button>
      </nav>
    </div>
  );
};

export default Navbar;
