import React from "react";

const Song = ({ currentSong }) => {
  return (
    <div className="songBox">
      <img src={currentSong.cover} alt="song cover"></img>
      <h1>{currentSong.name}</h1>
      <h3>{currentSong.artist}</h3>
    </div>
  );
};

export default Song;
