import React from "react";

const LibrarySong = ({
  song,
  setCurrentSong,
  songs,
  isPlaying,
  audioRef,
  setSongs,
  id,
}) => {
  const songSelectHandler = async () => {
    const selectedSong = song;
    await setCurrentSong(selectedSong);
    // active state
    const newSong = songs.map((song) => {
      if (song.id === id) {
        return {
          ...song,
          active: true,
        };
      } else {
        return {
          ...song,
          active: false,
        };
      }
    });
    setSongs(newSong);
    // hraje pisnicka ?
    if (isPlaying) audioRef.current.play();
  };

  return (
    <div
      className={`librarySong ${song.active ? "selected" : ""} `}
      onClick={songSelectHandler}
    >
      <img src={song.cover} alt={song.name}></img>
      <div className="songDescription">
        <h3>{song.name}</h3>
        <h4>{song.artist}</h4>
      </div>
    </div>
  );
};

export default LibrarySong;
