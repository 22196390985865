import React, { useState, useEffect } from "react";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";

const Player = ({
  currentSong,
  isPlaying,
  setIsPlaying,
  audioRef,
  playSongHandler,
  songInfo,
  setSongInfo,
  songs,
  setCurrentSong,
  setSongs,
}) => {
  useEffect(() => {
    const newSongs = songs.map((song) => {
      if (song.id === currentSong.id) {
        return {
          ...song,
          active: true,
        };
      } else {
        return {
          ...song,
          active: false,
        };
      }
    });
    setSongs(newSongs);
  }, [currentSong]);
  //time format
  const getTime = (time) => {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  };

  const dragHandler = (e) => {
    audioRef.current.currentTime = e.target.value;
    setSongInfo({ ...songInfo, currentTime: e.target.value });
  };

  const skipHandler = async (smer) => {
    let currentIndex = songs.findIndex((song) => song.id === currentSong.id);
    if (smer === "skipForward") {
      await setCurrentSong(songs[(currentIndex + 1) % songs.length]);
    }
    if (smer === "skipBack") {
      if ((currentIndex - 1) % songs.length === -1) {
        await setCurrentSong(songs[songs.length - 1]);
        if (isPlaying) audioRef.current.play();
        return;
      }
      await setCurrentSong(songs[(currentIndex - 1) % songs.length]);
    }
    if (isPlaying) audioRef.current.play();
  };

  return (
    <div className="player">
      <div className="playerBox">
        <div className="timeControl">
          <p>{getTime(songInfo.currentTime)}</p>
          <input
            onChange={dragHandler}
            min={0}
            max={songInfo.duration || 0}
            value={songInfo.currentTime}
            type="range"
            name="songRange"
            id="songRange"
          />
          <p>{songInfo.duration ? getTime(songInfo.duration) : "0:00"}</p>
        </div>
        <div className="playControl">
          <FontAwesomeIcon
            className="back"
            icon={faBackward}
            onClick={() => skipHandler("skipBack")}
          />
          <FontAwesomeIcon
            className="play"
            onClick={playSongHandler}
            icon={isPlaying ? faPause : faPlay}
          />
          <FontAwesomeIcon
            className="forward"
            icon={faForward}
            onClick={() => skipHandler("skipForward")}
          />
        </div>
      </div>
    </div>
  );
};

export default Player;
